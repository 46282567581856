<template>
  <div class="UseDailyPracticeRecord" style="margin: -15px -15px">
    <div class="Previous_left">
      <div class="Previous_module">模块</div>
      <a-menu style="width: 100%" v-model="selectId" mode="inline">
        <a-menu-item
            v-for="(item, index) in templateList"
            @click="selectStatus(item.id,item.name)"
            :key="item.id"
        >
          {{ item.name }}
        </a-menu-item>
      </a-menu>
      <div style="display: flex;justify-content: right;padding: 5px">
        <a-pagination
            size="small"
            v-model="temPosition.pageNum"
            :total="temPosition.total"
            @change="menuPageChange"/>
      </div>
    </div>
    <div class="Previous_right">
      <div class="Previous_right_top">
        <div class="Previous_right_title">用户每日一练记录</div>
        <div>
          <div class="top_cont">
            <div class="EduRec-top-input">
              <span>日期：</span>
              <a-range-picker v-model="datePicker" style="margin-right: 15px;width: 220px" @change="onChange" />
            </div>
            <div class="EduRec-top-input">
              <a-button style="margin-right: 20px" @click="exportModule" >
                <a-icon style="color: #00A854;" type="file-excel"/>
                导出该模块
              </a-button>
              <a-button  @click="exportAllModule" >
                <a-icon style="color: #00A854;" type="file-excel"/>
                导出全部模块
              </a-button>
            </div>
          </div>
        </div>
        <div class="activity-table">
<!--           :rowKey="record=>record.id"-->
          <a-table
              @change="orderChange"
              :pagination="false"
              :columns="columns"
              :data-source="data">
          <span slot="location" slot-scope="text, record">
          </span>
          </a-table>
          <!--分页-->
          <div class="pageView">
          <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
              pagination.current
            }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
            <a-pagination v-model="pagination.current"
                          :total="pagination.total"
                          :page-size.sync="pagination.pageSize"
                          show-size-changer show-quick-jumper
                          @showSizeChange="showSizeChange"
                          @change="pageChange" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import {
  getModuleMenu,
  getExamExerciseDay,
  examExerciseDayExport,
} from "@/service/titleExam_api";

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: "6%"
  },{
    title: '医院',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
    align: 'center',
    ellipsis: true,
    width:'20%'
  },
  {
    title: '科室',
    dataIndex: 'hospitalOffices',
    key: 'hospitalOffices',
    align: 'center',
    width:'8%'
  },
  {
    title: '职称',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    align: 'center',
    ellipsis: true,
    width:'8%'
  },
  {
    title: '日期',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'center',
    width: '13%'
  },
  {
    title: '完成题目数',
    dataIndex: 'questionCount',
    key: 'questionCount',
    align: 'center',
    width: '10%',
    sorter: true
  },
];
let data = [];
export default {
  name: "UseDailyPracticeRecord",
  data(){
    return{
      selectId: [],
      selectTemName:'',
      templateList: [],
      pagination:{
        total:0,
        pageSize: 10,
        current:1,
      },
      spinning:false,
      data,
      columns,
      startTime:null,
      endTime:null,

      temPosition:{
        pageNum:1,
        pageSize:10,
        total:0,
      },
      order:null,
      datePicker:null,
    }
  },
  async created() {
    await this.getLeftTemplate(); //获取左侧菜单列表
    await this.NumberQuestionsDay(); //模拟考试-用户每日完成数量
  },
  methods:{
    async menuPageChange(current, pageSize) {
      this.temPosition.pageNum = current;
      await this.getLeftTemplate();
      await this.NumberQuestionsDay(); //模拟考试-用户每日完成数量
    },
    //获取左侧菜单列表
    async getLeftTemplate() {
      let data = {
        page:this.temPosition.pageNum
      }
      const res = await getModuleMenu(data);
      if (res.code === 200) {
        this.templateList = res.data;
        this.selectId[0] = res.data[0].id;
        this.selectTemName = res.data[0].name;
        this.temPosition.total = res.count;
      }
    },
    //获取右侧  模拟考试-用户每日完成数量
    async NumberQuestionsDay() {
      let data = {
        typeId:this.selectId[0], //模块id
        startTime:this.startTime,
        endTime:this.endTime,
        curPage:this.pagination.current,
        pageSize:this.pagination.pageSize,
        sortType:this.order, //question_up题目数升序，question_down题目数降序
      }
      const res = await getExamExerciseDay(data);
      if (res.statuscode === 200) {
        this.data = res.data;
        this.pagination.total = res.count;
      }
    },
    // 第一个表格排序
    orderChange(page, filters, sorter) {
      //获取排序规则 order --- 升序ascend   降序descend;  key  --  columnKey
      if(sorter.columnKey === 'questionCount'){ // 完成题目数量

        if(sorter.order === 'ascend'){ //升序

          this.order = 'question_down';

        }else if(sorter.order === 'descend'){ //降序

          this.order = 'question_up';

        }else {
          this.order = null;
        }
        this.pagination.current = 1;
        this.NumberQuestionsDay();
      }
    },
    selectStatus(value,name) {
      this.selectId[0] = value;
      this.selectTemName = name;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;

      this.startTime = null;
      this.endTime = null;
      this.datePicker = null;

      //切换菜单右侧列表切换内容
      this.NumberQuestionsDay();
    },

    showSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.NumberQuestionsDay();
    },
    pageChange(pageNum,pageSize){
      this.pagination.current = pageNum;
      this.pagination.pageSize = pageSize;
      this.NumberQuestionsDay();
    },
    onChange(date, dateString) {
      console.log(this.datePicker)
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.pagination.current = 1;
      this.NumberQuestionsDay();
    },
    async exportModule() {
      let ExelName = this.selectTemName;
      let data = {
        startTime: this.startTime,
        endTime: this.endTime,
        typeId:this.selectId[0],
        sortType:this.order,
        ExelName: ExelName
      }
      await examExerciseDayExport(data);
    },
    async exportAllModule() {
      let ExelName = '用户每日一练记录';
      let data = {
        ExelName: ExelName,
        sortType:this.order,
      }
      await examExerciseDayExport(data);
    },

  }
}
</script>

<style scoped lang="scss">
.ant-menu{
  border: none;
}
.UseDailyPracticeRecord{
  min-height: 110%;
  display: flex;
  justify-content: space-between;
  .Previous_left{
    width: 20%;
    //background-color: #ee7474;
    .Previous_module{
      margin-left: 25px;
      margin-top: 10px;
    }

  }
  .Previous_right{
    border-left: 15px solid #F0F2F5;
    width: 80%;
    .Previous_right_top{
    }
    .Previous_right_title{
      width: 98%;
      margin: auto;
      font-weight: bold;
      color: #333333;
      padding-top: 15px;
    }
    .top_cont{
      width: 98%;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
    .EduRec-top-input{
      height: 50px;
      padding-top: 15px;
    }

    .activity-table{
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }
    .pageView{
      display: flex;
      justify-content: space-between;
      z-index: 3;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .pageLeft{
      font-size: 15px;color: #929292
    }
  }
}
</style>
